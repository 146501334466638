import React from 'react'
import ReactMarkdown from 'react-markdown'

export const MarkdownDisplay = ({ file = '/privacy-policy.md' }) => {
	const [content, setContent] = React.useState('')

	React.useEffect(() => {
		fetch(file)
			.then((response) => response.text())
			.then((text) => setContent(text))
			.catch(console.error)
	}, [file])

	return (
		<div style={{ overflowY: 'scroll', height: '100vh' }}>
			<ReactMarkdown children={content} />
		</div>
	)
}
